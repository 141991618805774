<template>
  <step-activity-layout :title="$t('module2.part3.activity.title')" :activity-finished="isGameFinished" :activity-started="isGameStarted">
    <template v-slot:intro>
      <activity-intro @start="isGameStarted = true"
        :title="$t('module2.part3.activity.intro.title')"
        :rules-content="$t('module2.part3.activity.intro.rules')"
        :rules-image="require('@/assets/module2/part3/activity-intro-illu.svg')"
      >
      </activity-intro>
    </template>
    <template v-slot:content>
      <div  v-if="!isGameFinished" class="quizz-wrapper" ref="quizz">
        <quizz-activity class="m2p3-quizz" :is-multiple="false" :questions="questions" @quizz-finished="quizzFinished" @question-index-updated="onQuestionUpdated" :enable-correction="true">
          <template v-slot:top="{ context, questionIndex, questionsCount }">
            <div class="quizz-top">
              <p class="title">{{ context }}</p>
              <p class="page-index">
                {{ questionIndex }} / {{ questionsCount }}
              </p>
            </div>
          </template>
          <template v-slot:question="{ question, label }">
            <div class="question-wrapper">
              <p class="label">{{ label }}</p>
              <p class="question">{{ question }}</p>
            </div>
          </template>
          <template v-slot:answer="{ label, isRight, isChecked, showAnswers }">
            <p class="answer-wrapper" :class="{ right: isRight, checked: isChecked, 'show-answers': showAnswers }">{{ label }}</p>
          </template>
          <template v-slot:correction="{ correction }">
            <p class="correction" v-html="correction"></p>
          </template>
        </quizz-activity>
      </div>
      <activity-result-block v-else @replay-activity="replayActivity" :is-custom-content="true">
        <template v-slot:content>
          <img class="result-image" :src="require('@/assets/module2/part3/activity-intro-illu.svg')">
        </template>
      </activity-result-block>
    </template>
    <template v-slot:bottom>
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <a :href="opinionFormUrl" target="_blank" rel="noopener" class="link-button-wrapper">
          <app-button-layout>
            {{ $t('global.navigation.giveOpinion') }}
          </app-button-layout>
        </a>
        <app-button-layout color="alternate-secondary" @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-activity-layout>
</template>

<script>
import StepActivityLayout from '@/components/training/StepActivityLayout'
import ActivityResultBlock from '@/components/training/ActivityResultBlock'
import QuizzActivity from '@/components/training/QuizzActivity'
import ActivityIntro from '@/components/training/ActivityIntro'
import useTrainingUtils from '@/utils/useTrainingUtils'
import useActivityUtils from '@/utils/useActivityUtils'
import GLOBAL_URLS from '@/constants/GLOBAL_URLS'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module2Part3Activity',
  components: { StepActivityLayout, ActivityResultBlock, QuizzActivity, ActivityIntro, AppButtonLayout },
  setup () {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    const { isGameStarted, isGameFinished, replayActivity, onQuestionUpdated, onActivityFinished } = useActivityUtils()
    const opinionFormUrl = GLOBAL_URLS.OPINION_FORMS_URLS.MODULE_2
    return { goToNextStep, resumeLater, opinionFormUrl, isGameFinished, isGameStarted, replayActivity, onQuestionUpdated, onActivityFinished }
  },
  data () {
    return {
      questions: [
        {
          name: 'question1',
          context: this.$t('module2.part3.activity.questions.question1.context'),
          label: this.$t('module2.part3.activity.questions.question1.title'),
          question: this.$t('module2.part3.activity.questions.question1.question'),
          rightAnswer: 'b',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question1.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question1.answers.answer2')
            },
            {
              name: 'c',
              label: this.$t('module2.part3.activity.questions.question1.answers.answer3')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question1.correction')
        },
        {
          name: 'question2',
          context: this.$t('module2.part3.activity.questions.question2.context'),
          label: this.$t('module2.part3.activity.questions.question2.title'),
          question: this.$t('module2.part3.activity.questions.question2.question'),
          rightAnswer: 'a',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question2.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question2.answers.answer2')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question2.correction')
        },
        {
          name: 'question3',
          context: this.$t('module2.part3.activity.questions.question3.context'),
          label: this.$t('module2.part3.activity.questions.question3.title'),
          question: this.$t('module2.part3.activity.questions.question3.question'),
          rightAnswer: 'a',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question3.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question3.answers.answer2')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question3.correction')
        },
        {
          name: 'question4',
          context: this.$t('module2.part3.activity.questions.question4.context'),
          label: this.$t('module2.part3.activity.questions.question4.title'),
          question: this.$t('module2.part3.activity.questions.question4.question'),
          rightAnswer: 'b',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question4.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question4.answers.answer2')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question4.correction')
        },
        {
          name: 'question5',
          context: this.$t('module2.part3.activity.questions.question5.context'),
          label: this.$t('module2.part3.activity.questions.question5.title'),
          question: this.$t('module2.part3.activity.questions.question5.question'),
          rightAnswer: 'b',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question5.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question5.answers.answer2')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question5.correction')
        },
        {
          name: 'question6',
          context: this.$t('module2.part3.activity.questions.question6.context'),
          label: this.$t('module2.part3.activity.questions.question6.title'),
          question: this.$t('module2.part3.activity.questions.question6.question'),
          rightAnswer: 'a',
          answers: [
            {
              name: 'a',
              label: this.$t('module2.part3.activity.questions.question6.answers.answer1')
            },
            {
              name: 'b',
              label: this.$t('module2.part3.activity.questions.question6.answers.answer2')
            }
          ],
          correction: this.$t('module2.part3.activity.questions.question6.correction')
        }
      ]
    }
  },
  methods: {
    quizzFinished () {
      this.onActivityFinished()
    }
  }
}
</script>

<style lang="scss" scoped>
.training-warning {
  margin-top: $space-s;
  color: $c-text;
}
.quizz-top {
  position: relative;
  font-size: $fz-xs;
  font-weight: $fw-l;
  .title {
    text-align: center;
    color: $c-primary;
    text-transform: uppercase;
  }
  .page-index {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    background-color: $c-text-lighter;
    border-radius: $radius;
    padding: 3px $space-s;
  }
}
.question-wrapper {
  margin-top: $space-sm;
  color: $c-text-dark;
  font-size: $fz-l;
  text-align: center;
  font-weight: $fw-m;
  margin-bottom: $space-m;
  .question {
    margin-top: $space-m;
    font-weight: 700;
  }
}
.answer-wrapper {
  text-align: center;
  color: $c-text;
  padding: $space-xs $space-m;
  min-height: 60px;
  font-weight: $fw-m;
  display: flex;
  align-items: center;
  justify-content: center;
  &.show-answers {
    &.right {
      color: $c-primary;
      font-weight: $fw-l;
      animation-name: win;
      animation-duration: 1.2s;
    }
  }
}
.result-image {
  width: 100%;
}
</style>

<style lang="scss">
.quizz-activity.m2p3-quizz {
  .quizz-wrapper {
    .answers {
      display: flex;
      flex-direction: column;
      .answer {
        height: auto;
      }
    }
  }
}
</style>
