import useTrainingUtils from '@/utils/useTrainingUtils'
import { computed, ref } from 'vue'
import USER_LEVELS from '@/constants/USER_LEVELS'

const useActivityUtils = () => {
  const { setStepData, currentStepDatas, setPartData, currentPartDatas, setStepFinished } = useTrainingUtils()

  const isGameFinished = computed({
    get () {
      return currentStepDatas.value?.isGameFinished
    },
    set (value) {
      setStepData({ key: 'isGameFinished', data: value })
    }
  })

  const isGameStarted = ref(false)

  const scoreActivity = computed({
    get () {
      return currentStepDatas.value?.scoreActivity
    },
    set (value) {
      setStepData({ key: 'scoreActivity', data: value })
    }
  })

  const points = computed({
    get () {
      return currentStepDatas.value?.points
    },
    set (value) {
      setStepData({ key: 'points', data: value })
    }
  })

  const userLevel = computed({
    get () {
      return currentPartDatas.value?.userLevel
    },
    set (value) {
      setPartData({ key: 'userLevel', data: value })
    }
  })

  const hasUserLevel = computed(() => !!userLevel.value)
  const isUserConfident = computed(() => userLevel.value === USER_LEVELS.GOOD)

  const setUserLevel = (level) => {
    userLevel.value = level
  }

  const onActivityFinished = async () => {
    isGameFinished.value = true
    await setStepFinished()
  }

  const replayActivity = () => {
    isGameFinished.value = false
    isGameStarted.value = false
  }

  const onQuestionUpdated = () => {
    const quizzHeadY = window.document.querySelector('.training .step-activity-layout .head')
      .getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: quizzHeadY, behavior: 'smooth' })
  }

  return { isGameFinished, isGameStarted, scoreActivity, points, hasUserLevel, isUserConfident, userLevel, setUserLevel, replayActivity, onQuestionUpdated, onActivityFinished }
}

export default useActivityUtils
