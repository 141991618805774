const GLOBAL_URLS = {
  OSCAR_SITE: 'https://programme-oscar-cee.fr/poser-une-question-a-oscar/',
  MODULE_NAMES: {
    MODULE_1: 'module1',
    MODULE_2: 'module2',
    MODULE_3: 'module3'
  },
  OPINION_FORMS_URLS: {
    MODULE_1: 'https://s1.sphinxonline.net/surveyserver/s/ATEE/OSCAR/Module1.htm',
    MODULE_2: 'https://s1.sphinxonline.net/surveyserver/s/ATEE/OSCAR/Module2.htm',
    MODULE_3: 'https://s1.sphinxonline.net/surveyserver/s/ATEE/OSCAR/Module3.htm'
  }
}

export default GLOBAL_URLS
