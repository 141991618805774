<template>
  <div class="activity-intro">
    <div class="content-wrapper">
      <div class="content-col">
        <div class="head">
          <p class="label">
            <slot name="label">
              {{ $t('training.activity.intro.label') }}
            </slot>
          </p>
          <p class="title">{{ title }}</p>
        </div>
        <div class="rules-wrapper">
          <p class="label">
            {{ $t('training.activity.intro.rulesLabel') }}
          </p>
          <slot name="rules">
            <p class="rules-content" v-html="rulesContent"></p>
            <img class="rules-image" :src="rulesImage">
          </slot>
        </div>
      </div>
      <div class="button-col">
        <div class="button-wrapper">
          <app-button-layout @click="$emit('start')">
            <slot name="buttonLabel">
              {{ $t('training.global.goToActivity') }}
            </slot>
          </app-button-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
export default {
  name: 'ActivityIntro',
  components: { AppButtonLayout },
  emits: ['start'],
  props: {
    title: {
      type: String,
      required: true
    },
    rulesContent: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    rulesImage: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-intro {
  background-color: $c-primary;
  height: 100%;
  .content-wrapper {
    max-width: $training-max-width;
    margin: 0 auto;
    color: #fff;
    .head {
      padding: $space-m;
      .label {
        text-transform: uppercase;
        font-size: $fz-xs;
        font-weight: $fw-l;
      }
      .title {
        margin-top: $space-xs;
        font-size: $fz-ml;
        font-weight: $fw-l;
      }
    }
    .rules-wrapper {
      border-radius: $radius-s;
      background-color: #fff;
      padding: $space-m;
      color: $c-text-dark;
      margin: 0 $space-s $space-s $space-s;
      .label {
        color: $c-text-lighter;
        text-transform: uppercase;
        font-size: $fz-xs;
        font-weight: $fw-l;
        margin-bottom: $space-xs;
      }
      .rules-content {
        margin-bottom: $space-m;
      }
      .rules-image {
        max-width: 100%;
      }
    }
  }
  .button-col {
    background-color: #043755;
    .button-wrapper {
      background-color: #fff;
      padding: $space-s $space-ml;
      border-radius: 0 0 $radius $radius;
      .app-button-layout {
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .activity-intro {
    .content-wrapper {
      display: grid;
      gap: $space-l;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 $space-s $space-s $space-s;
      .head {
        padding: $space-m 0;
      }
      .button-col {
        align-self: start;
        padding-top: $space-l;
        background-color: transparent;
        .button-wrapper {
          padding: $space-l $space-ml;
          border-radius: $radius;
        }
      }
    }
  }
}
</style>
