<template>
  <div class="app-block activity-result-block">
    <div class="app-block-title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="app-block-content">
      <slot name="content"></slot>
      <div class="content" v-if="!isCustomContent">
        <div class="result" :class="{ success: success }">
          <div class="result-icon">
            <slot name="resultIcon"></slot>
          </div>
          <div class="result-sentence">
            <slot name="resultSentence"></slot>
          </div>
        </div>
        <div class="auto-evaluate-wrapper" v-if="isAutoEvaluation">
          <div class="auto-evaluation">
            <p class="label">
              {{ $t('training.autoEvaluation') }}
            </p>
            <slot name="autoEvaluation"></slot>
          </div>
          <div class="annotation">
            <slot name="annotation"></slot>
          </div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <slot name="buttons">
          <app-button-layout color="alternate-secondary" @click="replayActivity">
            {{ $t('training.global.replayActivity') }}
          </app-button-layout>
          <app-button-layout color="alternate-secondary" @click="replayLesson">
            {{ $t('training.global.replayLesson') }}
          </app-button-layout>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import { STEPS } from '@/data/trainingModules'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'ActivityResultBlock',
  components: { AppButtonLayout },
  emits: ['replay-activity'],
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return i18n.global.t('training.global.activityResult')
      }
    },
    success: {
      type: Boolean,
      required: false
    },
    isAutoEvaluation: {
      type: Boolean,
      required: false,
      default: true
    },
    isCustomContent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup () {
    const { currentModule, currentPart } = useTrainingUtils()
    return { currentModule, currentPart }
  },
  methods: {
    replayActivity () {
      this.$emit('replay-activity')
    },
    replayLesson () {
      this.$router.push({
        name: VIEWS_NAMES.TRAINING,
        params: {
          modulePath: this.currentModule.path,
          partPath: this.currentPart.path,
          stepPath: STEPS.LESSON.PATH
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-result-block.app-block {
  .app-block-content {
    padding: $space-s;
  }
}

.activity-result-block {
  .content {
    .result {
      padding: $space-m $space-l;
      width: 100%;
      border-radius: $radius;
      background-color: rgba(#eb8333, 0.11);
      display: flex;
      align-items: center;
      &.success {
        background-color: rgba($c-primary, 0.11);
      }
      .result-icon {
        flex-shrink: 0;
      }
      .result-sentence {
        flex-grow: 1;
        text-align: center;
        font-size: $fz-s;
        font-weight: $fw-l;
      }
    }
    .auto-evaluate-wrapper {
      display: flex;
      align-items: center;
      margin-top: $space-s;
      .auto-evaluation {
        padding: $space-sm $space-s;
        background-color: $c-background;
        border-radius: $radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        .label {
          font-size: $fz-xs;
          font-weight: $fw-l;
          text-transform: uppercase;
          margin-bottom: $space-sm;
        }
      }
      .annotation {
        flex-grow: 1;
        text-align: center;
        font-size: $fz-s;
        font-weight: $fw-m;
        padding: 0 $space-s;
      }
    }
  }
  .buttons-wrapper {
    margin-top: $space-sm;
    .app-button-layout + .app-button-layout {
      margin-top: $space-s;
    }
    .app-button-layout {
      width: 100%;
    }
  }
}

</style>
